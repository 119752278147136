import React,{useState} from 'react'
import SettingList from '../Components/SettingComp/SettingList';
import MainDashBoard from './MainDashBoard';

const Setting = () => {
    
    return (
        <>
             <MainDashBoard>
           <SettingList/>
           </MainDashBoard>
        </>
    )
}

export default Setting
