import React from "react";
import { Link } from "react-router-dom";
const settingItem = [
  { icon: " fas fa-user", name: "Account", path: "/personalbusinessacc" },
  { icon: "fas fa-user", name: "Privacy" },
  { icon: "fas fa-user", name: "Legal" },
];

const SettingList = () => {
  return (
    <>
      <div className="container top-align">
        <div className="row">
          <div className="col-lg-6 col-md-6 offset-md-3">
            <div className="faqlist-bg">
              <ul>
                {settingItem.map((data, index) => {
                  return (
                    <Link to={`${data.path}`} key={index} className="faq-list">
                      
                      <li>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="faq-list-icon-heading">
                            <i className={data.icon}></i>
                            <span>{data.name}</span>
                          </div>
                          <div>
                            <i className="fas fa-arrow-right"></i>
                          </div>
                        </div>
                    </li>
                    </Link>
                  );
                })}
              </ul>
            </div>

            <div className="faqlogout-bg mt-4">
              <div className="d-flex align-items-center justify-content-between">
                <div className="faq-list-icon-heading">
                  <i className="fas fa-sign-out-alt"></i>
                  <span>Log Out</span>
                </div>
                <div>
                  <i className="fas fa-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingList;
