import React, { useState } from "react";
import ScheduleNavTab from "../Components/ScheduleBookingComp/ScheduleNavTab";
import MainDashBoard from "./MainDashBoard";

const ScheduleBookingPage = () => {
  const [show, setShow] = useState(false);
  // const handleRoute=(route)=>{
  //     route && setShow(false)
  // }
  return (
    <>
      <MainDashBoard>
        <ScheduleNavTab />
      </MainDashBoard>
    </>
  );
};

export default ScheduleBookingPage;
