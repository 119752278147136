import React from "react";
import UpdateCardPhoto from "../MainDashboardComp/UpdateCardPhoto";
import MainDashBoardIcon from "../MainDashboardComp/MainDashBoardIcon";
import CurrentRide from "../MainDashboardComp/CurrentRide";
import BtnCustom from "../BtnCustom";
import { getRequest } from "../../Helpers/RequestApi";
import { toast, ToastContainer } from "react-toastify";

class LandingComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.user = JSON.parse(localStorage.getItem("user"));
  }

  componentDidMount = () => {
    getRequest("passenger/dashboard?api_key=" + this.user.api_key)
      .then((res) => {
        this.setState({ ...this.state, data: res.data });
      })
      .catch((error) => {
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  render() {
    <ToastContainer />;
    return (
      <div className="container">
        <div className="dashboard-firstpart">
          <div className="row stat-wrapper">
            <div className="col-md-4">
              <div className="custom-card">
                <i class="fa-regular fa-calendar"></i>
                <h4>
                  {this.state.data.count && this.state.data.count.total_booking
                    ? this.state.data.count.total_booking
                    : 0}
                </h4>
                <p>Total - {process.env.ENV}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="custom-card">
                <i class="fa-regular fa-calendar-check"></i>
                <h4>
                  {this.state.data.count &&
                  this.state.data.count.total_completed_booking
                    ? this.state.data.count.total_completed_booking
                    : 0}
                </h4>
                <p>Completed</p>
              </div>
            </div>
            {/* <div className="col-md-4">
              <div className="custom-card">
                <i class="fa-regular fa-calendar-minus"></i>
                <h4>110</h4>
                <p>SCHEDULE</p>
              </div>
            </div> */}
            <div className="col-md-4">
              <div className="custom-card">
                <i class="fa-regular fa-calendar-xmark"></i>
                <h4>
                  {this.state.data.count &&
                  this.state.data.count.total_cancelled_booking
                    ? this.state.data.count.total_cancelled_booking
                    : 0}
                </h4>
                <p>Cancelled</p>
              </div>
            </div>
          </div>
          {!(this.state.data.validate && this.state.data.validate.is_card_added? true: false) ? (
            <div className="row">
              <div className="col-md-4">
                <div className="custom-card missed-info">
                  <img className="missed-info-img" src="images/add-card.png" />
                  <h4>PAYMENT INFO MISSING</h4>
                  <BtnCustom name={"Add Payment Info"} path={"carddetails"} />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
export default LandingComp;
