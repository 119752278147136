import React, { useState } from "react";
import DashBoardHeader from "../Components/MainDashboardComp/DashBoardHeader";
import SideBar from "../Components/MainDashboardComp/SideBar";

const MainDashBoard = ({children}) => {
  const [show, setShow] = useState(false);
  // const handleRoute = (route) => {
  //   route && setShow(false);
  // };
  return (
    <>
      <DashBoardHeader onIcon={() => setShow(!show)} click={show} />
      <div className={show ? "open-menu body-wrapper" : "body-wrapper"}>
        {children}
      </div>
      <SideBar  sidebarshow={show} />
    </>
  );
};
// handleRoute={handleRoute}
export default MainDashBoard;
