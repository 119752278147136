import React from "react";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import { toast, ToastContainer } from "react-toastify";
import { getRequest } from "../Helpers/RequestApi";

const BookingComp = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [booking, setBooking] = React.useState("");

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  // function getBookingDetails (event) {
  //   console.log(event,event.target,'asdsdsdsd');
  //    // let user = JSON.parse(localStorage.getItem("user"));
  //   // getRequest("passenger/booking-details?booking_ref_id=" + props.booking_ref_id + "&api_key=" + user.api_key)
  //   // .then((res) => {
  //   //   setBooking(res.data);
  //   // })
  //   // .catch((error) => {
  //   //   toast.error(error.response.data.msg, {
  //   //     position: "top-right",
  //   //     autoClose: 3000,
  //   //     hideProgressBar: false,
  //   //     closeOnClick: true,
  //   //     pauseOnHover: true,
  //   //     draggable: true,
  //   //     progress: undefined,
  //   //   });
  //   // });
  // };
  
  function BookingModal(props) {
    if (!props.show) {
      return "";
    }
    return (
      <Modal size="xl" show={isOpen} onHide={hideModal}>
        <ModalHeader>
          <i className="fa fa-close close-modal" onClick={hideModal}></i>
        </ModalHeader>
        <Modal.Body>
          <div className="row no-gutter">
            <div className="col-md-12">
              <div className="custom-card booking-list-design d-flex justify-content-between align-items-center">
                <h5 className="w-50">
                  <span className="badge badge-dim mr-2">
                    BK. {props.booking.booking_ref_id}
                  </span>
                </h5>
              </div>
            </div>
            <div className="col-md-7">
              <div className="booking-list-design">
                <div className="custom-card booking-list-design">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="date-time-wrapper">
                        <span className="date-wrap">
                          {props.booking.pickup_date}
                        </span>
                      </div>

                      <span className="time-wrap">
                        ({props.booking.pickup_time})
                      </span>
                    </div>
                    <div className="col-md-6 route-info">
                      <h5>
                        <i className="fa fa-map-marker-alt"></i>{" "}
                        {props.booking.start}
                      </h5>

                      <h5>
                        <i className="fa fa-map-pin"></i> {props.booking.end}
                      </h5>
                      {/* <h5>
                        <i className="fa fa-clock"></i>{" "}
                        <strong>00:31:40</strong> <i className="fa fa-road"></i>{" "}
                        <strong>19.014 kms</strong>
                      </h5> */}
                    </div>
                    <div className="col-md-3 fare-wrapper">
                      <p>$ {props.booking.final_fare}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" booking-list-design ">
                <div className="custom-card booking-list-design">
                  <div className="row">
                    <div className="col-md-4 route-info">
                      <span className="badge badge-dim">
                        Journey Information
                      </span>
                      <h5 className="mt-4">
                        Flight No.
                        <strong>
                          {props.booking.service_type == "from_airport"
                            ? props.booking.flight_no
                            : "-"}{" "}
                        </strong>
                      </h5>
                      <h5 className="mt-2">
                        Airline Name
                        <strong>
                          {props.booking.service_type == "from_airport"
                            ? props.booking.flight_name
                            : "-"}{" "}
                        </strong>
                      </h5>
                      <h5>
                        <i className="fa fa-user"></i>{" "}
                        <strong>{props.booking.passengers}</strong> &nbsp;{" "}
                        <i className="fa fa-suitcase"></i>{" "}
                        <strong>{props.booking.luggage}</strong>{" "}
                        <i className="fa fa-child"></i>{" "}
                        <strong>{props.booking.baby_seat}</strong>
                      </h5>
                    </div>
                    <div className="col-md-8 route-info">
                      <span className="badge badge-dim">
                        Special Instruction
                      </span>
                      <p className="mt-4">
                        {props.booking.special_instruction}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" booking-list-design ">
                <div className="custom-card booking-list-design">
                  <div className="row">
                    <div className="col-md-3">
                      <img
                        className="vehicle-img"
                        src={props.booking.fleet.image_path}
                        alt="img"
                      />
                    </div>
                    <div className="col-md-6 route-info">
                      {/* <span className="badge badge-dim">
                        Economy Class Executive Sedan
                      </span> */}
                      <h5 className="mt-2">
                        <strong>{props.booking.fleet.name}</strong>
                      </h5>
                      <h5>
                        <i className="fa fa-user"></i>{" "}
                        <strong>{props.booking.fleet.passengers}</strong> &nbsp;{" "}
                        <i className="fa fa-suitcase"></i>{" "}
                        <strong>{props.booking.fleet.luggage}</strong> &nbsp;{" "}
                        <i className="fa fa-briefcase"></i>{" "}
                        <strong>{props.booking.fleet.hand_luggage}</strong>
                      </h5>
                    </div>
                    <div className="col-md-3  fare-wrapper text-center">
                      {/* <p className="mt-1"> REG321</p> */}
                    </div>
                  </div>
                </div>
              </div>
              {props.booking.driver ? (
                <div className=" booking-list-design ">
                  <div className="custom-card booking-list-design">
                    <div className="row">
                      <div className="col-md-3 text-center">
                        {/* <img
                        className="mr-3 profile-img"
                        src="https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?size=626&amp;ext=jpg&amp;ga=GA1.1.2070450023.1642204800"
                        alt="img"
                      /> */}
                      </div>
                      <div className="col-md-6 route-info">
                        {/* <span className="badge badge-dim ">
                        Driver ID <strong>7 </strong>
                      </span> */}
                        {/* <h5 className="mt-2">
                        {" "}
                        <strong>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star dim"></i>
                        </strong>
                      </h5> */}
                        <h5 className="mt-2">
                          Name <strong> {props.booking.driver.name}</strong>{" "}
                          &nbsp;
                        </h5>
                      </div>
                      <div className="col-md-3  fare-wrapper text-center">
                        <p className="mt-0">
                          {" "}
                          {props.booking.driver.vehicle_plate_no}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {props.booking.passenger ? (
                <div className=" booking-list-design ">
                  <div className="custom-card booking-list-design">
                    <div className="row">
                      <div className="col-md-3 text-center">
                        <img
                          className="mr-3 profile-img"
                          src={props.booking.passenger.image_path}
                          alt="img"
                        />
                      </div>
                      <div className="col-md-5 route-info">
                        <h5 className="mt-2">
                          Name{" "}
                          <strong>{props.booking.passenger_name} </strong>{" "}
                          &nbsp;
                        </h5>
                        <h5>
                          Email{" "}
                          <strong>{props.booking.passenger_email}</strong>
                        </h5>
                        <h5>
                          Cell No.{" "}
                          <strong>
                            {props.booking.passenger_cell_no}
                          </strong>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="booking-list-design ">
                <div className="custom-card booking-list-design">
                  <span className="badge badge-dim ">
                    Invoice ID{" "}
                    <strong>{props.booking.invoice.invoice_id} ({props.booking.invoice.created_at})</strong>
                  </span>{" "}
                  &nbsp;
                  {/* <span className="badge badge-dim ">
                    Transition ID{" "}
                    <strong>TR576 (Tue 15 Feb 2022 - 11:59 AM) </strong>
                  </span> */}
                  <br />
                  <table className=" mt-2 table table-striped fare-wrapper-table table-sm">
                    <tbody>
                      {/* <tr>
                        <th scope="col">Fare</th>
                        <td className="text-right">$150.00</td>
                      </tr>
                      <tr>
                        <th scope="col">Child Seat</th>
                        <td className="text-right">$50.00</td>
                      </tr>
                      <tr>
                        <th scope="col">Airport Fee</th>
                        <td className="text-right">$50.00</td>
                      </tr>
                      <tr>
                        <th scope="col">Tolls</th>
                        <td className="text-right">$50.00</td>
                      </tr>

                      <tr>
                        <th scope="col">Discount on Fare</th>
                        <td className="text-right">$0.00</td>
                      </tr>
                      <tr>
                        <th scope="col">Subtotal</th>
                        <td className="text-right">$150.00</td>
                      </tr>
                      <tr>
                        <th scope="col">GST</th>
                        <td className="text-right">$0.00</td>
                      </tr> */}
                      <tr>
                        <th scope="col">Total</th>
                        <td className="text-right">
                          <strong>${props.booking.final_fare}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <hr />
                  <div className="row">
                    <div className="col-md-5">
                      {/* <p>
                        <img
                          src="src/assets/images/cards/visa.png"
                          className="img-fluid"
                        />{" "}
                        **** **** **** 1111
                      </p> */}
                      <p className="mt-2">
                        <strong className="badge badge-info">{props.booking.payment_method}</strong>{" "}
                        &nbsp;{" "}
                        <strong className="badge badge-danger">{props.booking.is_paid?'Paid':'Unpaid'}</strong>
                      </p>
                    </div>

                    {/* <div className="col-md-7 text-right">
                      <p className="badge badge-dim">
                        Payment will be Pre-Authorized in 7 hours from now
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="custom-card booking-list-design">
                <strong className=" badge badge-success">{props.booking.booking_status}</strong>
                <strong className=" badge badge-danger">{props.booking.is_cancelled?'Cancelled':''}</strong>
              </div>

              <div className="custom-card booking-list-design">
                <div className="map-canvas">
                  <iframe width="100%"
                    src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d52982.89691128352!2d151.10511082787025!3d-33.904303807288066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x6b12b0f11b3383db%3A0xafdf355d5a4b6577!2sSydney%20Airport%20(SYD)%2C%20Sydney%20NSW%202020%2C%20Australia!3m2!1d-33.9500344!2d151.1819124!4m5!1s0x6b12bad379914ad5%3A0x5017d681632b290!2sConcord%20NSW%2C%20Australia!3m2!1d-33.8559625!2d151.10331309999998!5e0!3m2!1sen!2snp!4v1644923331539!5m2!1sen!2snp"
                    allowfullscreen=""
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
              {/* <div className="custom-card booking-list-design">
                <table className="table table-striped fare-wrapper-table table-sm">
                  <tbody>
                    <tr>
                      <th scope="col">Booking Created</th>
                      <td className="text-right">
                        <strong>19 Feb 2022 (02:00 AM) </strong>
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">Booking Started</th>
                      <td className="text-right">
                        <strong>21 Feb 2022 (03:00 AM) </strong>
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">Driver on Destination</th>
                      <td className="text-right">
                        <strong>21 Feb 2022 (03:15 AM)</strong>
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">Passenger Onboard</th>
                      <td className="text-right">
                        <strong>21 Feb 2022 (03:20 AM)</strong>
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">Trip Started</th>
                      <td className="text-right">
                        <strong>21 Feb 2022 (03:21 AM)</strong>
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">Trip Ended</th>
                      <td className="text-right">
                        <strong>21 Feb 2022 (04:11 AM)</strong>
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">Booking Cancelled</th>
                      <td className="text-right">
                        <strong className="badge badge-danger">
                          21 Feb 2022 (03:20 AM)
                        </strong>{" "}
                        <br />{" "}
                        <small>
                          <strong>Passenger No show up</strong>
                        </small>
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">Payment</th>
                      <td className="text-right">
                        <strong className="badge badge-danger">Failed</strong>{" "}
                        <br />{" "}
                        <small>
                          <strong>Insufficient Balance</strong>
                        </small>
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">Payment</th>
                      <td className="text-right">
                        <strong className="badge badge-success">Paid</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      <ToastContainer />
      <div className="custom-card booking-list-design" onClick={showModal}>
        <div className="row">
          <div className="col-md-3">
            <div className="date-time-wrapper">
              <span className="date-wrap">{props.data.pickup_date}</span>
              {/* <span className="date-wrap">31</span>
              <div>
                <span className="month-year-wrap">
                  Mar<br/> 2022
                </span>
              </div> */}
            </div>

            <span className="time-wrap">({props.data.pickup_time})</span>
          </div>
          <div className="col-md-6 route-info">
            <p className="mb-2">
              <strong className="badge badge-secondary">
                #BK.ID {props.data.booking_ref_id}
              </strong>{" "}
            </p>
            <h5>
              <i className="fa fa-map-marker-alt"></i> {props.data.start}
            </h5>
            <h5>
              <i className="fa fa-map-pin"></i> {props.data.end}
            </h5>
          </div>
          <div className="col-md-3 fare-wrapper">
            <span className="badge badge-warning">
              {props.data.booking_status}
            </span>
            <p>$ {props.data.final_fare}</p>
          </div>
        </div>
      </div>
      <BookingModal show={isOpen} onHide={hideModal} booking={props.data} />
    </>
  );
};

export default BookingComp;
