import React from "react";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/esm/Accordion";
const supportItem = [
  { icon: "fas fa-user", name: "Account" },
  { icon: " fas fa-search", name: "Lost and Found" },
  { icon: "far fa-comment", name: "Feedback" },
];

// const supportItem=[

//     {icon:" fas fa-user", name:"Account"},
//     {icon:"fas fa-search", name:"Lost and Found"},
//     {icon:"fas fa-comment", name:"Feedback"},
// ]

const SupportList = () => {
  return (
    <>
      <div className="container top-align">
        <div className="row">
          <div className="col-lg-8 col-md-8 offset-md-2">
            <div className="faqlist-bg">
              {/* <ul>
                                {supportItem.map((data,index)=>{
                                    return(

                                <Link to="" key={index} className='faq-list'>
                                    <li onClick={()=>{window.location.pathname=data.path}}>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='faq-list-icon-heading'>
                                                <i className={data.icon}></i><span>{data.name}</span>
                                            </div>
                                            <div>
                                            <i className="fas fa-arrow-right"></i>
                                            </div>
                                            
                                        </div>
                                    </li>
                                </Link>
                                    )
                                })}
                            </ul> */}
              {supportItem.map((data, index) => {
                return (
                  <Accordion key={index}>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-between icon-colouring">
                          <div className="faq-list-icon-heading">
                            <i className={data.icon}></i>
                            <span>{data.name}</span>
                          </div>
                          <div>
                            <i className="fas fa-arrow-right"></i>
                          </div>
                        </div>
                      </Accordion.Header>

                      <Accordion.Body>
                          <form className="support-acc-form-bg">
                      <input type="email" className="form-control mb-2"  placeholder="Booking ID"/>
                      <textarea className="form-control mb-2"  rows="2" placeholder="Message"/>
                      <Link to="" className="btn-small">Submit</Link>
                      </form>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportList;
