import React, { useState } from "react";
import { Link } from "react-router-dom";
import Card from "./Card";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Modal";
import PaymentCard from "../AddCardComp/PaymentCard";

const CardPayment = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="container">
        <div className="top-align mb-5">
          <div className="row">
          <div className="col-md-4">
            <Card className=" active-card"/>
            <Card />
            <Card />
          </div>
          <div className="col-md-4 offset-md-2">
            <Link to="" className="btn-main mt-5" onClick={handleShow}>
              Add Card
            </Link>
            <Modal show={show} onHide={handleClose} className="payment-modal">
              {/* <Modal.Header closeButton></Modal.Header> */}
               <PaymentCard/>
            </Modal>
          </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardPayment;
