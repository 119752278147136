import "bootstrap/dist/css/bootstrap.min.css";
// import 'bootstrap-daterangepicker/daterangepicker.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import AddCard from "./Pages/AddCard";
import EditProfile from "./Pages/EditProfile";
import Faq from "./Pages/Faq";
import Invoice from "./Pages/Invoice";
import Landing from "./Pages/Landing";
import LoginPage from "./Pages/LoginPage";
import MainDashBoard from "./Pages/MainDashBoard";
import Notification from "./Pages/Notification";
import Payment from "./Pages/Payment";
import CardInfo from "./Pages/Card";
import Register from "./Pages/Register";
import ScheduleBookingPage from "./Pages/ScheduleBookingPage";
import Setting from "./Pages/Setting";
import Statements from "./Pages/Statements";
import Support from "./Pages/Support";
import TermsOfService from "./Pages/TermsOfService";
import UserLoginPage from "./Pages/UserLoginPage";
import VerifyCode from "./Pages/VerifyCode";
import PhoneProceed from "./Components/LoginComp/LoginForm";
import ViewProfilePage from "./Pages/ViewProfilePage";
import ProtectedRoutes from "./auth/ProtectedRoutes";
import AuthRoutes from "./auth/AuthRoutes";
import ForgetPassword from "./Pages/ForgetPassword";
import VerifyForgotCode from "./Pages/VerifyForgotCode";
import ResetPassword from "./Pages/ResetPassword";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AuthRoutes />}>
          <Route exact path="/" element={<Navigate to="/login" replace />} />
          <Route exact path="/login" element={<UserLoginPage />} />
          <Route exact path="/getphone" element={<PhoneProceed />} />
          <Route exact path="/verifycode" element={<VerifyCode />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/forget-password" element={<ForgetPassword />} />
          <Route exact path="/verify-forgot-code" element={<VerifyForgotCode />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
        </Route>

        <Route path="/" element={<ProtectedRoutes />}>
          <Route exact path="/termsofservices" element={<TermsOfService />} />
          <Route exact path="/addCard" element={<AddCard />} />
          <Route exact path="/dashboard" element={<Landing />} />
          <Route exact path="/maindashboard" element={<MainDashBoard />} />
          <Route exact path="/booking" element={<ScheduleBookingPage />} />
          <Route exact path="/viewprofilepage" element={<ViewProfilePage />} />
          <Route exact path="/notification" element={<Notification />} />
          <Route exact path="/statements" element={<Statements />} />
          <Route exact path="/invoice" element={<Invoice />} />
          <Route exact path="/faq" element={<Faq />} />
          <Route exact path="/support" element={<Support />} />
          <Route exact path="/setting" element={<Setting />} />
          <Route exact path="/editprofile" element={<EditProfile />} />
          <Route exact path="/payment" element={<Payment />} />
          <Route exact path="/carddetails" element={<CardInfo />} />
        </Route>

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
