import React,{useState} from 'react'
import MainDashBoard from './MainDashBoard';
import StatementCompTable from '../Components/StatementComp/StatementCompTable';

const Statements = props => {
    console.log(props)
    return (
        <>
        

           <MainDashBoard>
            <StatementCompTable />
           </MainDashBoard>
     
       </>
    )
}

export default Statements
