import React from 'react'
import EditProfileForm from '../Components/EditProfileComp/EditProfileForm'

const EditProfile = () => {
    return (
        <>
            <EditProfileForm/>
        </>
    )
}

export default EditProfile
