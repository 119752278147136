import React from "react";

const StatementTableData = () => {
  const tableHead = ["Date", "From", "To"];
  const tableData = [
    {
      date: "08 dec 2021",
      from: "12 dec 2021",
      to: "18 dec 2021",
    },
    {
      date: "08 dec 2021",
      from: "12 dec 2021",
      to: "18 dec 2021",
    },
    {
      date: "08 dec 2021",
      from: "12 dec 2021",
      to: "18 dec 2021",
    },
  ];
  return (
    <>
      <table className="table table-striped">
        <thead>
          <tr>
            {tableHead.map((data, index) => {
              return (
                <th scope="col" key={index}>
                  {data}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {tableData.map((datas, index) => {
            console.log(datas);
            return (
              <tr key={index}>
                <td> {datas.date}</td>
                <td> {datas.from}</td>
                <td> {datas.to}</td>
                <td>
                  <i className="fas fa-download table-download"></i>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default StatementTableData;
