import React from 'react'
import CardPayment from '../Components/PaymentCardComp/CardPayment'
import MainDashBoard from './MainDashBoard'

const Payment = () => {
    return (
        <>
            <MainDashBoard>
                <CardPayment/>
            </MainDashBoard>
        </>
    )
}

export default Payment
