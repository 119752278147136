import React from "react";

const InputField = ({type,placeholder,value,onChange,disabled}) => {
  return (
    <>
      <input
        type={type?type:'text'}
        disabled={disabled?true:false}
        className="form-control inputstyle"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </>
  );
};

export default InputField;
