import React from 'react'
import { Link } from "react-router-dom";

const BtnOutline = ({path,name}) => {
    return (
        <>
            <div className='fix-btn-bg'>
            <Link to={`/${path}`} className='btn-outline'>{name}</Link>
            </div>
        </>
    )
}

export default BtnOutline
