import React, { useState } from "react";
import ViewPageContent from "../Components/ViewPageComponent/ViewPageContent";
import MainDashBoard from "./MainDashBoard";

const ViewProfilePage = () => {
  return (
    <>
      <MainDashBoard>
        <ViewPageContent />
      </MainDashBoard>
    </>
  );
};

export default ViewProfilePage;
