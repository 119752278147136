import React from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import { Form } from "react-bootstrap";
import { getRequest, postRequest } from "../../Helpers/RequestApi";
import { toast, ToastContainer } from "react-toastify";

class CardInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      show: false,
      btnDisable:false
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.user = JSON.parse(localStorage.getItem("user"));
  }
  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    let cardExpiry = this.state.card_expiry.split(/[ /]+/);
    let params = {
      card_number: this.state.card_number,
      card_code: this.state.card_code,
      card_holder_name: this.state.card_holder_name,
      expiry_month: cardExpiry[0],
      expiry_year: cardExpiry[1],
    };
    this.setState({btnDisable:true})
    postRequest("passenger/add-cc-profile?api_key=" + this.user.api_key,params)
      .then((res) => {
        toast.success(res.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.setState({btnDisable:false})
        this.setState({ show: false })
      })
      .catch((error) => {
        this.setState({btnDisable:false})
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  cardModal = () => {
    return (
      <Modal show={this.state.show} onHide={this.hideModal}>
        <ModalHeader>
          <i className="fa fa-close close-modal" onClick={this.hideModal}></i>
        </ModalHeader>
        <Modal.Body>
          <Form onSubmit={this.onFormSubmit}>
            <Form.Label>Card Holder Name</Form.Label>
            <Form.Control
              className="mb-3"
              type="text"
              placeholder="Felix Cardenas"
              name="card_holder_name"
              required
              onChange={this.handleChange}
            />
            <Form.Label>Card Number</Form.Label>
            <Form.Control
              className="mb-3"
              type="number"
              placeholder="411**********"
              name="card_number"
              required
              onChange={this.handleChange}
            />
            <div className="row">
              <div className="col-md-8">
                <Form.Label>Card Exp. Date</Form.Label>
                <Form.Control
                  className="mb-3"
                  type="text"
                  placeholder="MM / YY"
                  required
                  name="card_expiry"
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-md-4">
                <Form.Label>CVC Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="123"
                  required
                  name="card_code"
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <button className="btn btn-main btn-block mt-3" disabled={this.state.btnDisable} >Add Card</button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  componentDidMount = () => {
    // [this.isOpen, this.setIsOpen] = React.useState(false);
    getRequest("passenger/cards?api_key=" + this.user.api_key)
      .then((res) => {
        this.setState({ ...this.state, data: res.data });
      })
      .catch((error) => {
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  render() {
    return (
      <>
      <ToastContainer></ToastContainer>
        <div className="container mt-120 card-page">
          <div className="row">
            <div className="col-md-5">
              <Link to=" " onClick={this.showModal} className="btn-main btn-sm mb-2">
                Add New Card
              </Link>
              {Object.entries(this.state.data).map((v, i) => {
                return (
                  <div className="custom-card pay-card">
                    <div className="row">
                      <div className="col-sm-6 text-left">
                        <h5>{v[1].card_holder_name}</h5>
                        <p className="mb-2"></p>
                        {/* <img
                        src="https://133vip.test/assets/frontend/dist/img/credit/visa.png"
                        className="img-fluid"
                      /> */}
                      </div>
                      <div className="col-sm-6 text-right">
                        {/* <span className="badge badge-success">Active</span> */}
                        <p className="mb-2">Card Number</p>
                        <h5>**** **** **** {v[1].card_number_masked}</h5>
                        <span>
                          Expires on <strong> {v[1].expiration_date} </strong>
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <this.cardModal />
      </>
    );
  }
}

export default CardInfo;
