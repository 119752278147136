import React,{useState} from 'react'
import SideBar from '../Components/MainDashboardComp/SideBar';
import DashBoardHeader from '../Components/MainDashboardComp/DashBoardHeader';
import SupportList from '../Components/SupportComp/SupportList';
import MainDashBoard from './MainDashBoard';


const Support = () => {
    const [show,setShow]= useState(false);
    return (
        <>
            <MainDashBoard>
           <SupportList/>
           </MainDashBoard>

        </>
    )
}

export default Support
