import React, { useState } from "react";
import InputField from "../InputField";
import BtnOutline from "../BtnOutline";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { postRequest } from "../../Helpers/RequestApi";
import { Button } from "react-bootstrap";

const buttonStyle = {
  'width':'100%',
  'color': '#ffffff',
  'font-weight': '600',
  'background': 'rgba(24, 128, 56, 1)',
  'padding': '10px 30px',
  'font-size': '16px',
  'border-radius': '5px',
  'letter-spacing': '0.2px',
  'text-decoration': 'none',
  'display': 'block',
  'text-align': 'center',
  'text-transform': 'uppercase',
};
const UserLoginForm = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate  = useNavigate ()

  const onSubmit = (e) => {
    setIsLoading(true);
    postRequest("passenger/login", { email: email, password: password })
      .then((res) => {
        setIsLoading(false);
        localStorage.setItem('user', JSON.stringify(res.data));
        navigate ("/dashboard");
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      });
  }

  return (
    <>
      <ToastContainer />
      <div className="page-alignment">
        <div className="form-wrapper">
          <div className="verify-content">
            <h1>Login</h1>
            <p>Provide following information to Login.</p>
          </div>
          <InputField
            type={"email"}
            placeholder={"Email/Phone"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <InputField
            type={"password"}
            placeholder={"Password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="fix-btn-bg">
            <Button style={buttonStyle}  className="" onClick={onSubmit} disabled={isLoading}>Login</Button>
          </div>
          <div className="didnotget-code">
            <Link to="/forget-password">Forget Password?</Link>
          </div>
          <span className="userlogin-or">OR</span>
          <BtnOutline name={"Register"} path={"getphone"} />
        </div>
      </div>
    </>
  );
};

export default UserLoginForm;
