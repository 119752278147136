import React from "react";
import BtnCustom from "../BtnCustom";
import InputFieldIcon from "../InputFieldIcon";

const PaymentCard = () => {
  return (
    <>
      <div className="page-alignment">
        <div className="form-wrapper">
          <div className="verify-content">
            <h1>Add Card</h1>
            <div className="row mt-4">
                <div className="col-md-12">
                <InputFieldIcon placeholder={"Enter Card Number"} icon={"far fa-credit-card"}/>
                </div>
            </div>
            <div className="row mt-4 gx-1">
                <div className="col-md-6">
                <InputFieldIcon placeholder={"MM/YY"} icon={"fas fa-info-circle"}/>
                </div>
                <div className="col-md-6">
                <InputFieldIcon placeholder={"CVC"} icon={"fas fa-info-circle"}/>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-12">
                <InputFieldIcon placeholder={"Name On Card"} icon={"fas fa-user"}/>
                </div>
            </div>
            <p><span><i className="fas fa-lock"></i></span> Your payment info will be stored Securely</p>
          </div>
          <BtnCustom name={"Add Card"} path={"landing"} />
        </div>
      </div>
    </>
  );
};

export default PaymentCard;
