import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import InvoiceStatementComp from "../Components/InvoiceStatementComp";
import InvoiceStatementTable from "../Components/InvoiceStatementTable";
import SearchInvoiceStatementCard from "../Components/SearchInvoiceStatementCard";
import { getRequest } from "../Helpers/RequestApi";
import MainDashBoard from "./MainDashBoard";
import TableChart from "../Components/TableChart";

class Invoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data:{invoices:'',paid:0,unpaid:0,total:0}
    };
    this.user = JSON.parse(localStorage.getItem("user"));
  }

  componentDidMount = () => {
    getRequest("passenger/invoices?api_key=" + this.user.api_key)
      .then((res) => {
        console.log(res);
        this.setState({...this.state, data: res.data})
      })
      .catch((error) => {
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  render() {
    return (
      <MainDashBoard>
        <ToastContainer />
        <div className="container top-align">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="row doc-stat-wrap ">
                <div className="col-md-4">
                  <div className="custom-card ">
                    <h3>{this.state.data.amounts && this.state.data.amounts.total?this.state.data.amounts.total:0}</h3>
                    <p>Total Invoice</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="custom-card">
                  <h3>${this.state.data.amounts && this.state.data.amounts.paid?this.state.data.amounts.paid:0}</h3>
                    <p style={{color: "green"}}>Paid</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="custom-card">
                  <h3>${this.state.data.amounts && this.state.data.amounts.unpaid?this.state.data.amounts.unpaid:0}</h3>
                    <p style={{color: "red"}}>Unpaid</p>
                  </div>
                </div>
              </div>
              <TableChart data={this.state.data.invoices}/>
            </div>
            <div className="col-md-4">
              {/* <SearchInvoiceStatementCard search="Invoice" /> */}
            </div>
          </div>
        </div>
      </MainDashBoard>
    );
  }
}
export default Invoice;
