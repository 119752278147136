import React from 'react'
import { Link } from 'react-router-dom'

const Backbtn = ({backbtnpath}) => {
 
    return (
        <>
        <div className=' mb-4 back-btn-div d-flex'>
            <Link to={`/${backbtnpath}`} className='back-btn'><i className="fas fa-chevron-left"></i>&nbsp;<span>Back</span></Link>
          
        </div>
        
        </>
        

    )
}

export default Backbtn
