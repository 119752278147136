import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { postRequest } from "../../Helpers/RequestApi";
import Logo from "../Logo";

const DashBoardHeader = ({ onIcon, click }) => {
  let user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const loggedUserName = user.name || "Passenger";

  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!isActive);
  };

  const logout = () => {
    postRequest("passenger/logout?api_key=" + user.api_key, {})
      .then((res) => {
        localStorage.removeItem("user");
        navigate("/login");
      })
      .catch((error) => {
        localStorage.removeItem("user");
        toast.error(error.response.data.msg || "Server error", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/login");
      });
  };

  return (
    <>
      <header className="web-header">
        <div className="row">
          <div className="col-md-9 left-side">
            <Link to="/dashboard">
              <Logo />
            </Link>
            <Link to="/booking">Booking</Link>
            <Link to="/invoice">Invoice</Link>
            <Link to="/faq">FAQ</Link>
            <Link to="/support">Support</Link>
            <Link to="">Privacy</Link>
            <Link to="">Legal</Link>
          </div>
          <div className="col-md-3 right-side">
            <Dropdown>
              <Dropdown.Toggle variant="" id="dropdown-basic">
                <i class="far fa-user"></i> {loggedUserName}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="/dashboard">Dashboard</Dropdown.Item>
                <Dropdown.Item href="/viewprofilepage">Profile</Dropdown.Item>
                <Dropdown.Item href="/carddetails">Card</Dropdown.Item>
                <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </header>
      <header class="mob-header">
        <Link to="/dashboard">
          <Logo />
        </Link>

        <a
          href="javascript:void(0"
          onClick={handleToggle}
          className="responsive-menu-btn"
        >
          <i className={isActive ? "fa fa-bars" : "fa fa-times"}></i>
        </a>
      </header>
      <ul className={isActive ? "nav mob-nav hide" : "nav mob-nav show"}>
        <li>
          <Link to="/booking">Booking</Link>{" "}
        </li>
        <li>
          <Link to="/invoice">Invoice</Link>{" "}
        </li>
        <li>
          <Link to="/faq">FAQ</Link>{" "}
        </li>
        <li>
          <Link to="/support">Support</Link>{" "}
        </li>
        <li>
          <Link to="">Privacy</Link>{" "}
        </li>
        <li>
          <Link to="">Legal</Link>
        </li>
        <li>
          <a href="/dashboard">Dashboard</a>
        </li>
        <li>
          <a href="/viewprofilepage">Profile</a>
        </li>
        <li>
          <a href="/carddetails">Card</a>
        </li>
        <li>
          <a href="javascript:void()" onClick={logout}>
            Logout
          </a>
        </li>
      </ul>
    </>
  );
};

export default DashBoardHeader;
