import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { postRequest } from "../../Helpers/RequestApi";


const RegisterForm = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [isLoading, setIsLoading] = useState(false);
  let otpData = JSON.parse(localStorage.getItem("otpData"));
  const navigate = useNavigate();

  if(!otpData || !otpData.resend_id){
    navigate("/login")
  }

  const onSubmit = (e) => {
    e.preventDefault();
    let params={ resend_id: otpData && otpData.resend_id?otpData.resend_id:'',name:name,email:email,password:password};
    setIsLoading(true)
    postRequest("passenger/register", params)
      .then((res) => {
        setIsLoading(false)
        localStorage.removeItem('otpData')
        localStorage.setItem('user', JSON.stringify(res.data))
        navigate("/termsofservices")
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      })
  }
  return (
    <>
     <ToastContainer/>
      <div className="page-alignment">
        <div className="form-wrapper">
          <div className="verify-content">
            <h1>Register</h1>
            <p>
            Please enter your personal details.
            </p>
          </div>
          <Form onSubmit={onSubmit}>
            <Form.Control className="mb-3" type="text" placeholder={"Full Name"} name="name" required onChange={(e) => setName(e.target.value)} />
            <Form.Control className="mb-3" type="email" placeholder={"Email"} name="email" required onChange={(e) => setEmail(e.target.value)} />
            <Form.Control className="mb-3" type="password" placeholder={"Password"} name="password" required onChange={(e) => setPassword(e.target.value)} />
            <button className="btn btn-main btn-block" disabled={isLoading}>Register</button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default RegisterForm;
