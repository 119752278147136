import React, { useState } from "react";
import Logo from "../Logo";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import BtnCustom from "../BtnCustom";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { postRequest } from "../../Helpers/RequestApi";
import { ToastContainer, toast } from "react-toastify";

const buttonStyle = {
  width: "100%",
  color: "#ffffff",
  "font-weight": "600",
  background: "rgba(24, 128, 56, 1)",
  padding: "10px 30px",
  "font-size": "16px",
  "border-radius": "5px",
  "letter-spacing": "0.2px",
  "text-decoration": "none",
  display: "block",
  "text-align": "center",
  "text-transform": "uppercase",
};

const LoginForm = () => {
  const [country_code, setCC] = useState();
  const [country_number, setCN] = useState();
  const [phone, setPhone] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = (e) => {
    let params={ country_code: country_code,country_number: country_number,phone: phone };
    setIsLoading(true);
    postRequest("passenger/send-verification-code", params)
      .then((res) => {
        localStorage.setItem('otpData', JSON.stringify(res.data));
        setIsLoading(false);
        navigate("/verifycode");
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="page-alignment">
        <div className="form-wrapper">
          <Logo />
          <p className="login-para">
            Proceed with you Phone number. We'll send your verfiy code.
          </p>
          <IntlTelInput
            containerClassName="intl-tel-input"
            inputClassName="form-control"
            onPhoneNumberChange={(b, n, c) => {
              setCC(c.iso2)
              setCN(c.dialCode)
              setPhone(n)
            }}
          />
          <Button style={buttonStyle} className="mt-2" onClick={onSubmit} disabled={isLoading}>Get a Code</Button>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
