import { Link } from "react-router-dom";
import React from "react";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import IntlTelInput from "react-intl-tel-input";
import { Form } from "react-bootstrap";
import { Button } from "bootstrap";
import { getRequest, postRequest } from "../../Helpers/RequestApi";
import { toast, ToastContainer } from "react-toastify";

class ViewPageContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      show: false,
      show1: false,
      btnDisable: false,
      btnDisable1: false,
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal1 = this.showModal1.bind(this);
    this.hideModal1 = this.hideModal1.bind(this);
    this.user = JSON.parse(localStorage.getItem("user"));
  }
  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };
  showModal1 = () => {
    this.setState({ show1: true });
  };

  hideModal1 = () => {
    this.setState({ show1: false });
  };

  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value });
  };

  onImageChange = event => {
    if (event.target.files && event.target.files[0]) {
      this.setState({image: event.target.files[0]});
    }
  };

  updateProfile = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if(this.state.image){
      formData.append("image",this.state.image,this.state.image.name);
    }
    formData.append("name",this.state.name || this.state.data.name);
    this.setState({ btnDisable: true });
    postRequest("passenger/update-profile?api_key=" + this.user.api_key,formData,true)
      .then((res) => {
        toast.success(res.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.setState({ ...this.state, data: res.data });
        this.setState({ btnDisable: false });
        this.setState({ show: false });
      })
      .catch((error) => {
        this.setState({ btnDisable: false });
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  updatePassword = (e) => {
    e.preventDefault();
    let params = {
      old_password: this.state.old_password,
      new_password: this.state.new_password,
      reconfirm_password: this.state.reconfirm_password
    };
    this.setState({ btnDisable1: true });
    postRequest("passenger/reset-password?api_key=" + this.user.api_key, params)
      .then((res) => {
        toast.success(res.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.setState({ btnDisable1: false });
        this.setState({ show1: false });
      })
      .catch((error) => {
        this.setState({ btnDisable1: false });
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  detailModal = () => {
    return (
      <Modal show={this.state.show} onHide={this.hideModal}>
        <ModalHeader>
          <i className="fa fa-close close-modal" onClick={this.hideModal}></i>
        </ModalHeader>
        <Modal.Body>
          <Form onSubmit={this.updateProfile}>
            <Form.Label>Update Profile Image</Form.Label>
            <Form.Control className="mb-3" type="file" name="image" onChange={this.onImageChange}/>
            <Form.Label>Update Full Name</Form.Label>
            <Form.Control className="mb-3"  type="text" placeholder="Enter Full Name" name="name" onChange={this.handleChange}/>
            {/* <Form.Label>Update Email Address</Form.Label>
            <Form.Control className="mb-3"  type="text" placeholder="passx@mailinator.com" />
            <Form.Label>Update Phone Number</Form.Label>
            <IntlTelInput containerClassName="intl-tel-input" inputClassName="form-control" /> */}
           <button className="btn btn-main btn-block mt-3" disabled={this.state.btnDisable}>Update</button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  passwordModal = () => {
    return (
      <Modal show={this.state.show1} onHide={this.hideModal1}>
        <ModalHeader>
          <i className="fa fa-close close-modal" onClick={this.hideModal1}></i>
        </ModalHeader>
        <Modal.Body>
           <Form onSubmit={this.updatePassword}>
             <Form.Label>Enter Old Password</Form.Label>
             <Form.Control className="mb-3" type="password" name="old_password" required onChange={this.handleChange} />
             <Form.Label>Enter New Password</Form.Label>
             <Form.Control className="mb-3" type="password" name="new_password" required onChange={this.handleChange} />
             <Form.Label>Re-Type New Password</Form.Label>
             <Form.Control className="mb-3" type="password" name="reconfirm_password" required onChange={this.handleChange} />
             <button className="btn btn-main btn-block" disabled={this.state.btnDisable1}>Update</button>
           </Form>
         </Modal.Body>
      </Modal>
    );
  };

  componentDidMount = () => {
    getRequest("passenger/profile?api_key=" + this.user.api_key)
      .then((res) => {
        this.setState({ ...this.state, data: res.data });
      })
      .catch((error) => {
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  render() {
    return (
      <>
       <ToastContainer></ToastContainer>
        <div className="container mt-120 profile-page">
          <div className="row">
            <div className="col-md-6">
              <div className="custom-card ">
                <div className="profile-wrapper">
                  <div className="profile-img">
                    <img
                      src={this.state.data && this.state.data.image_path?this.state.data.image_path:'../images/default.jpg'}
                      className="user-image img-circle pr-2 mb-2"
                      alt="User Image"
                    ></img>
                    {/* <ul className=" rating-wrapper ">
                      <li className="list-inline-item">
                        <i className="fa fa-star active"></i>
                      </li>
                      <li className="list-inline-item">
                        <i className="fa fa-star"></i>
                      </li>
                    </ul> */}
                  </div>
                  <div className="profile-details w-320">
                    <div className="border-box mb-4">
                      <h5 className="mb-2">Personal Information</h5>
                      <h4 className="mt-0">{this.state.data.name}</h4>
                      <ul className="list-unstyled">
                        <li>
                          <h6>{this.state.data.country_number}{this.state.data.phone}</h6>
                        </li>
                        <li>
                          <h6>{this.state.data.cell_no_additional}</h6>
                        </li>
                        <li>
                          <h6>{this.state.data.email} </h6>
                        </li>
                        <li>
                          <h6>{this.state.data.email_additional} </h6>
                        </li>
                        <li>
                          <h6>{this.state.data.home_address} </h6>
                        </li>
                        <li>
                          <h6>{this.state.data.business_address} </h6>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-4 btn-wrap">
                  <Link to=" " onClick={this.showModal} className="btn-main btn-sm">
                    Edit Details
                  </Link>
                  <Link to=" " onClick={this.showModal1} className="btn-outline ml-2 btn-sm">
                    Edit Password
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <this.detailModal />
        <this.passwordModal />
      </>
    );
  }
}

export default ViewPageContent;