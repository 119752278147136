import React from "react";
import CardDetails from "../Components/cardinfo/CardInfo";
import MainDashBoard from "./MainDashBoard";

const CardInfo = () => {
  return(
  <>
    <MainDashBoard>
        <CardDetails />
    </MainDashBoard>
  </>
  )
};

export default CardInfo;
