import React from "react";

const TableChart = (props) => {
  const tableHead = ["Date", "Invoice ID", "Amount", "Status"];
  const tableData = props.data?props.data:[]
  return (
    <>
    <div className="custom-card">
      <table className="table table-striped">
        <thead>
          <tr>
            {tableHead.map((data, index) => {
              return <th scope="col" key={index}>{data}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {tableData.map((datas, index) => {
            return (
              <tr key={index}>
                <td> {datas.generated_date}</td>
                <td> {datas.invoice_id}</td>
                <td> ${datas.invoice_amount}</td>
                {
                  datas.payment_status == 1 ?  <td style={{color: "green"}}>Paid</td> : <td style={{color: "red"}}>Unpaid</td>
                }
              </tr>
            );
          })}
        </tbody>
      </table>
      </div>
    </>
  );
};

export default TableChart;
