import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import BookingComp from "../BookingComp";
import { Form } from "react-bootstrap";
import { getRequest } from "../../Helpers/RequestApi";
import { toast } from "react-toastify";

class ScheduleNavTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allData: {},
      completedData: {},
      cancelledData: {},
    };
  }
  
  loadBookings = (type) => {
    let user = JSON.parse(localStorage.getItem("user"));
    getRequest("passenger/bookings?type=" + type + "&api_key=" + user.api_key)
      .then((res) => {
        if (type == "all") {
          this.setState({ allData: res.data });
        } else if (type == "completed") {
          this.setState({ completedData: res.data });
        } else {
          this.setState({ cancelledData: res.data });
        }
      })
      .catch((error) => {
        // toast.error(error.response.data.msg, {
        //   position: "top-right",
        //   autoClose: 3000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
      });
  };

  componentDidMount = () => {
    this.loadBookings("all");
    this.loadBookings("completed");
    this.loadBookings("cancelled");
  };

  render() {
    return (
      <div className="container top-align">
        <div className="row">
          <div className="col-lg-12">
            <Tabs>
              <TabList>
                <Tab>
                  <i class="fa-solid fa-car"></i> All
                </Tab>
                <Tab>
                  <i class="fa-solid fa-user-check"></i> Completed
                </Tab>
                <Tab>
                  <i class="fa-solid fa-xmark"></i> Cancelled
                </Tab>
              </TabList>
              <TabPanel>
                {Object.entries(this.state.allData).map((v, i) => {
                  return <BookingComp data={v[1]} />;
                })}
              </TabPanel>
              <TabPanel>
                {Object.entries(this.state.completedData).map((v, i) => {
                  return <BookingComp data={v[1]} />;
                })}
              </TabPanel>
              <TabPanel>
                {Object.entries(this.state.cancelledData).map((v, i) => {
                  return <BookingComp data={v[1]} />;
                })}
              </TabPanel>
            </Tabs>
          </div>
          {/* <div className="col-lg-4"> */}
            {/* <div className="custom-card sidebar-filter">
                     <h3>Search Booking</h3>
                     <Form>
                       <Form.Label>Choose Date</Form.Label>
                       <Form.Control className="mb-3" type="text" />
                       <button className="btn btn-main btn-block mt-3">Search</button>
                     </Form>
                   </div> */}
          {/* </div> */}
        </div>
      </div>
    );
  }
}

export default ScheduleNavTab;
