import React from "react"; 

const Logo = () => {
  return (
    <>
      <figure className="logo">
        <img src="../images/logo.png" alt=""></img>
      </figure>
    </>
  );
};

export default Logo;
