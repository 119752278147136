import React, { useState } from "react";
import { Form } from "react-bootstrap";
import IntlTelInput from "react-intl-tel-input";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Logo from "../Components/Logo";
import { postRequest } from "../Helpers/RequestApi";

const VerifyForgotCode = () => {
  const [code, setCode] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  let otpForgotData = JSON.parse(localStorage.getItem("otpForgotData"));
  
  if (!otpForgotData) {
    navigate('/login');
  }

  const resendCode = (e) => {
    let params={ resend_id: otpForgotData && otpForgotData.resend_id?otpForgotData.resend_id:'' };
    postRequest("passenger/resend-password-verification-code", params)
      .then((res) => {
        localStorage.setItem('otpForgotData', JSON.stringify(res.data));
        toast.success(res.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      })
      .catch((error) => {
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    let params={ resend_id: otpForgotData && otpForgotData.resend_id?otpForgotData.resend_id:'',code: code };
    if (!params.code) {
      toast.error("Please enter verification code", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setIsLoading(true);
    postRequest("passenger/submit-forgot-password-code", params)
      .then((res) => {
        setIsLoading(false);
        localStorage.setItem('otpForgotData', JSON.stringify(res.data));
        navigate("/reset-password");
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="page-alignment">
        <div className="form-wrapper custom-card">
         <div className="verify-content">
         <h1>Enter Verification Code</h1>
         <p>We send code to <strong>{otpForgotData && otpForgotData.number?otpForgotData.number:''}</strong></p>
         </div>
         <Form onSubmit={onSubmit}>
            <Form.Control className="mb-2 text-center" maxLength={6} type="text" name="code" required onChange={(e) => setCode(e.target.value)} />
            <button className="btn btn-main btn-block" disabled={isLoading}>Submit</button>
         </Form>
         <div className="didnotget-code"> 
             <p>Didn't received code? </p>
             <Link to="" onClick={resendCode}>Resend Code</Link>
         </div>
        </div>
      </div>
    </>
  );
};

export default VerifyForgotCode;
