import React from 'react'
import RegisterForm from '../Components/RegisterComp/RegisterForm'

const Register = () => {
    return (
        <>
        <RegisterForm/>
        </>
    )
}

export default Register
