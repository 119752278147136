import React from 'react'
import PaymentCard from '../Components/AddCardComp/PaymentCard'

const AddCard = () => {
    return (
        <>
            <PaymentCard/>
        </>
    )
}

export default AddCard
