import React from "react";
import { Link } from "react-router-dom"; 
const sideBarMenu = [
  {
    name: "Booking",
    path:"/schedulebooking"
  },
  {
    name: "Invoice",
    path:"/invoice"
  },
  {
    name: "FAQ",
    path:"/faq"
  },
  {
    name: "Support",
    path:"/support"
  },
];
const sideBarExtLink = [
  { 
    name: "PRIVACY",
    path:"#!"
  },
  { 
      name: "LEGAL",
      path:"#!"
  }
]
const SideBar = ({sidebarshow}) => {
 
 
  return (
    <>
      <div className={sidebarshow?"open-menu sidebar":"sidebar"}>
          <ul className="menu-list">
    
            {sideBarMenu.map((menu, index) => {
              return (
                <>
                  <li > 
                <Link to={`${menu.path}`} key={index}>
                    <span>{menu.name}</span>
                  </Link>
                  </li>
                  </>
              );
            })}
                  {sideBarExtLink.map((menu, index) => {
              return (
                <>
          <a href={menu.path} key={index} target="_blank">
                  <li >
                    <span>{menu.name}</span>
                  </li>
          </a>
          </>
          );
        })}
          </ul>
       
      </div>
    </>
  );
};

export default SideBar;
