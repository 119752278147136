import React from "react";
import { Navigate, Outlet } from "react-router-dom";


const userAth=()=>{
  let user = JSON.parse(localStorage.getItem("user"))
  return (user != null)?true:false
}

const AuthRoutes=(props) =>{
  const auth=userAth()
  return auth?<Navigate to="/dashboard"/>:<Outlet/>
}

export default AuthRoutes;