import React from "react";
import TermServices from "../Components/TermsServicesComp/TermServices";

const TermsOfService = () => {
  return (
    <>
     <TermServices/>
    </>
  );
};

export default TermsOfService;
