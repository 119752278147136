import React from 'react'
import InputField from './InputField'

const InputFieldIcon = ({placeholder,icon}) => {
    return (
        <>
           <div className='d-flex justify-content-between align-items-center input-icon'>
           <span><i className={icon}></i></span>
           <InputField placeholder={placeholder}/>
            </div> 
        </>
    )
}

export default InputFieldIcon
