import React from 'react'
import VerifyForm from '../Components/VerifyCode/VerifyForm'

const VerifyCode = () => {
    return (
        <>
            <VerifyForm/>
        </>
    )
}

export default VerifyCode
