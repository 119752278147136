import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { postRequest } from "../../Helpers/RequestApi";
import Backbtn from "../Backbtn";
import BtnCustom from "../BtnCustom";
import InputField from "../InputField";

const buttonStyle = {
  width: "100%",
  color: "#ffffff",
  "font-weight": "600",
  background: "rgba(24, 128, 56, 1)",
  padding: "10px 30px",
  "font-size": "16px",
  "border-radius": "5px",
  "letter-spacing": "0.2px",
  "text-decoration": "none",
  display: "block",
  "text-align": "center",
  "text-transform": "uppercase",
};


const VerifyForm = () => {
  const [code, setCode] = useState();
  const [isLoading, setIsLoading] = useState(false);
  let otpData = JSON.parse(localStorage.getItem("otpData"));
  const navigate = useNavigate();


  const resendCode = (e) => {
    let params={ resend_id: otpData && otpData.resend_id?otpData.resend_id:'' };
    postRequest("passenger/resend-verification-code", params)
      .then((res) => {
        localStorage.setItem('otpData', JSON.stringify(res.data));
        toast.success(res.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      })
      .catch((error) => {
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    let params={ resend_id: otpData && otpData.resend_id?otpData.resend_id:'',code: code };
    setIsLoading(true)
    postRequest("passenger/verify-code", params)
      .then((res) => {
        setIsLoading(false)
        navigate("/register")
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      })
  }

  return (
    <>
    <ToastContainer/>
      <div className="page-alignment">
        <div className="form-wrapper">
         <Backbtn backbtnpath={"getphone"}/>
         <div className="verify-content">
         <h1>Enter verification Code</h1>
         <p>We send code to <strong>{otpData && otpData.number?otpData.number:''}</strong></p>
         </div>
         <Form onSubmit={onSubmit}>
            <Form.Control className="mb-3" type="text" name="code" required onChange={(e) => setCode(e.target.value)} />
            <button className="btn btn-main btn-block" disabled={isLoading}>Submit</button>
         </Form>
         <div className="didnotget-code">
             <p>Didn't received code? </p>
             <Link to="" onClick={resendCode}>Resend Code</Link>
         </div>
        </div>
      </div>
    </>
  );
};

export default VerifyForm;
