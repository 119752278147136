import React from 'react'
import UserLoginForm from '../Components/UserLoginComp/UserLoginForm'


const UserLoginPage = () => {
    return (
        <>
            <UserLoginForm/>
        </>
    )
}

export default UserLoginPage
