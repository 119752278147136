import React from 'react'
import SearchInvoiceStatementCard from '../SearchInvoiceStatementCard'
import StatementTable from './StatementTable'
import { Link } from 'react-router-dom'
import StatementTableData from './StatementTableData'
const StatementCompTable = ({}) => {
    return (
        <>
            <div className='container top-align'>
                <div className='row'> 
                    <div className="col-lg-8 col-md-8">
                        <div className="statement-invoice-bg mb-5">
                            <h2>Opening Balance &nbsp; $453.0</h2>
                            <div className="dr-cr-wrapper">
                                <div>
                                    <p>Dr.</p>
                                    <p>$48.12</p>
                                </div>
                                <div>
                                    <p>Cr.</p>
                                    <p>48.33</p>
                                </div>
                            </div>
                            <h5 className='mt-2'>Statement as of 26 DEC 2021</h5>
                        </div>
                        <StatementTableData/>
                    </div>
                    
                    <div className='col-lg-4 col-md-4 generate-statement'>
                    <Link to="" className="btn-main mb-4"> Generate Statement</Link>
                        <SearchInvoiceStatementCard search="Statement"/>
                    </div>
                </div>
           
            </div>
        </>
    )
}

export default StatementCompTable
