import React from "react";
import { Link } from "react-router-dom";

const EditProfileForm = () => {
  return (
    <>
      <div className="container section-gutter"> 
        <div className="row">
          <div className="col-lg-4 col-md-4 offset-md-4">
          <div className="edit-form-bg">
              <div className="edit-form-title">
                  <h5>
                      <Link to="/dashboard"><i className="fas fa-arrow-left"></i></Link>  <span className="ms-2">Edit Profile</span>
                  </h5>
                  <Link to="/dashboard"><i className="fas fa-check"></i></Link>
              </div>
            <form>
              <div class="mb-3">
                <label  className="form-label">
                  Name
                </label>
                <input type="text" className="form-control" value="sdfhgf"/>
              </div>
              <div className="mb-3">
                <label for="exampleInputPassword1" className="form-label">
                 Email
                </label>
                <input type="email" className="form-control" value="sdfhgf@gmail.com"/>
              </div>
              <div className="mb-3">
                <label for="exampleInputPassword1" className="form-label">
                 Phone
                </label>
                <input type="text" className="form-control" value="+977 9847621821"/>
              </div>
              
            </form>
            </div>
          </div>
        </div>
        </div>
     
    </>
  );
};

export default EditProfileForm;
