import React from 'react'
import { Link } from 'react-router-dom'

const SearchInvoiceStatementCard = ({search}) => {
    return (
        <>
            <div className='custom-card  sidebar-filter'>
                <h3>Search {search}</h3>
                <p>Choose Date</p>
                <form>
                <input type="text" className="form-control mb-3"/>
                <Link to="" className='btn-main'>Search</Link>
                </form>
            </div>
        </>
    )
}

export default SearchInvoiceStatementCard
