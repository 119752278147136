import React from 'react'
import LandingComp from '../Components/LandingComp/LandingComp'
import MainDashBoard from './MainDashBoard'

const Landing = () => {
    return (
        <>
            <MainDashBoard>
                <LandingComp/>
            </MainDashBoard>
        </>
    )
}

export default Landing
