import React,{useState} from 'react'
import { Link } from "react-router-dom";
import MainDashBoard from './MainDashBoard';

const notificationPromotion=[
    {icon:"fas fa-check-circle",heading:"Booking Accepted",desc:'Your booking has been accepted by driver Andrew'},
    {icon:"fas fa-car",heading:"Driver On Destination",desc:'Your driver has been reached to your destination'},
    {icon:"far fa-credit-card",heading:"Payment Done",desc:'Your payment has been done successfully'},
]

const Notification = () => {
    const [show,setShow]= useState(false);
    return (
        <>
            <MainDashBoard>
          <div className='container mt-120'>
          <ul className="mt-4">
                      {
                        notificationPromotion.map((data,index)=>{
                            return(

                      <Link to="" key={index} >
                          <li className="promotional-link mb-3">
                              <div className="d-flex align-items-center">
                                  <i className={data.icon}></i>
                                    <div className="mt-3">
                                        <h5>{data.heading}</h5>
                                        <p>{data.desc}</p>
                                    </div>
                              </div>
                          </li>
                      </Link>
                            )
                        })  
                      }
                  </ul>
          </div>
           </MainDashBoard>
        </>
    )
}

export default Notification
