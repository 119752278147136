import React from "react";

const Card = ({className}) => {
  return (
    <>
<div className={`payment-card-bg mt-4 ${className}`}>
        <h2>XXXX-XXXX-XXXX-1245</h2>
        <div className="d-flex justify-content-between align-items-center">
          <div className="card-left">
            <p>12/21</p>
            <p>Active</p>
          </div>
          <div className="card-right">
            <p>Verified</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
