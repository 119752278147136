import React, { useState } from "react";
import Accordion from "react-bootstrap/esm/Accordion";
import { Link } from "react-router-dom";
const faqItem = [
  { icon: "fas fa-car", name: "Driving With Us", desc: "Q What is Lorem Ipsum?", desc2:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's"},
  { icon: " fas fa-user", name: "Profile and Account" , desc: "Q What is Lorem Ipsum?", desc2:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's" },
  { icon: "far fa-credit-card", name: "Earning and Promos" , desc: "Q What is Lorem Ipsum?", desc2:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's"},
  { icon: "fas fa-file", name: "Policies and Other Info", desc: "Q What is Lorem Ipsum?", desc2:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's" },
];

const FaqList = () => {

  return (
    <>
      <div className="container top-align faq-page">
        <div className="row">
          <div className="col-lg-8 col-md-8 offset-md-2">
            <div className="faqlist-bg">
              {/* <ul>
                {faqItem.map((data, index) => {
                  return (
                    <div key={index} className="faq-list">
                      <li onClick={()=>setShow(!show)}>  
                                      
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="faq-list-icon-heading">
                            <i className={data.icon}></i>
                            <span>{data.name}</span>
                          </div>
                          <div>
                            <i className="fas fa-arrow-right" ></i>     
                          </div>
                        </div>
                      </li>
                      {show?
                            <div className="faq-div">
                              <div className="faq-qu-ans">
                                <p>Q. What is Lorem Ipsum?</p>
                                <p>
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry.
                                </p>
                              </div>
                              <div className="faq-qu-ans">
                                <p>Q. What is Lorem Ipsum?</p>
                                <p>
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry.
                                </p>
                              </div>
                            </div>:""} 
                    </div>
                  );
                })}
              </ul> */}
              {faqItem.map((data,index) => {
                return (
                  <Accordion key={index}>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-between icon-colouring">
                          <div className="faq-list-icon-heading">
                            <i className={data.icon}></i>
                            <span>{data.name}</span>
                          </div>
                          <div>
                            <i className="fas fa-arrow-right"></i>
                          </div>
                        </div>
                      </Accordion.Header>
                      
                      <Accordion.Body>
                          {data.desc?
                        <div className="faq-qu-ans mb-3">
                        {data.desc} <br/>
                        <br/>
                        {data.desc2}
                        </div>:""}
                        {data.desc?
                        <div className="faq-qu-ans mb-3">
                        {data.desc} <br/>
                        <br/>
                        {data.desc2}
                        </div>:""}
                      </Accordion.Body>
        
                    </Accordion.Item>
                  </Accordion>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqList;
